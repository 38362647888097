__webpack_public_path__ = "http://dev.local:8080/assets/";

import "../scss/main.scss";
import {onLoading} from "./_modules/website";
import {loadPage} from "./_modules/home";
import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';

var $ = require('jquery');


import './_modules/carousel';
import gsap from "gsap";

// get other plugins:
import ScrollTrigger from "gsap/ScrollTrigger";

// Si besoin de Jquery dans le terminal "npm install jquery"



    document.addEventListener("DOMContentLoaded", () => {
        onLoading();
        loadPage();
        gsap.registerPlugin(ScrollTrigger);


            ww = $(window).width();
            wh = $(window).height();

        if (ww <= 768) {

            gsap.utils.toArray(".book-item").forEach(item => {
                gsap.to(item, {
                    scrollTrigger: {
                        trigger: item, // Each item acts as its own trigger
                        scroller: ".content", // The scrollable container
                        start: "top center", // Start when the top of the item reaches the center of the container
                        onEnter: () => {
                            $('.message .livre-title').html($(item).data("titre"));
                            $('.message .infos').html($(item).data("infos"));
                            $('.extrait').html($(item).data("extrait"));
                        },
                        onEnterBack: () => {
                            $('.message .livre-title').html($(item).data("titre"));
                            $('.message .infos').html($(item).data("infos"));
                            $('.extrait').html($(item).data("extrait"));
                        }
                    }
                });

            });
        }

    });


